import React, { Component } from "react";
import "./topMenu.css";
import { Menu, Button } from "antd";
import { MenuOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "@reach/router";
import { withTranslation } from "react-i18next";

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { current: "home" };
  }

  UNSAFE_componentWillMount = () => {
    if (localStorage.getItem("loggedIn") === null) {
      localStorage.setItem("loggedIn", "false");
    }
  };

  UNSAFE_componentWillReceiveProps = (newprops) => {
    this.setState({ current: newprops.clickOnLogo });
  };

  handleClickMenu = (e) => {
    if (e.key) {
      this.setState({ current: e.key });
      this.props.menuSelectedHeaderTemplate(e.key);
    }
  };

  logout = () => {
    localStorage.setItem("loggedIn", "false");
    localStorage.setItem("BIPAWEBuserid", "");
    window.location.href = "/";
  };

  render() {
    const { t } = this.props;
    const loggedIn = localStorage.getItem("loggedIn");
    let menuItemColor = "";
    if (
      this.state.current.includes("home") ||
      this.props.clickOnLogo === "home"
    ) {
      menuItemColor = "white";
    } else {
      menuItemColor = "black";
    }
    return (
      <Menu
        style={{ textAlign: "right", backgroundColor: "transparent" }}
        onClick={this.handleClickMenu}
        selectedKeys={this.state.current}
        mode="horizontal"
        overflowedIndicator={<MenuOutlined style={{ color: menuItemColor }} />}
      >
        {!this.state.current.includes("home") && (
          <Menu.Item key="home">
            <Link
              style={{
                color: menuItemColor,
              }}
              to="/"
            >
              {t("homemenuitem")}
            </Link>
          </Menu.Item>
        )}
        {this.state.current.includes("home") && (
          <Menu.Item key="whathome">
            <a
              style={{
                color: menuItemColor,
              }}
              href="#what"
            >
              {t("whatsbipamenuitem")}
            </a>
          </Menu.Item>
        )}
        {this.state.current.includes("home") && (
          <Menu.Item key="howhome">
            <a
              style={{
                color: menuItemColor,
              }}
              href="#how"
            >
              {t("howitworksmenuitem")}
            </a>
          </Menu.Item>
        )}
        {this.state.current.includes("home") && (
          <Menu.Item key="featureshome">
            <a
              style={{
                color: menuItemColor,
              }}
              href="#features"
            >
              {t("bipafeaturesmenuitem")}
            </a>
          </Menu.Item>
        )}
        {this.state.current.includes("home") && (
          <Menu.Item key="whyhome">
            <a
              style={{
                color: menuItemColor,
              }}
              href="#why"
            >
              {t("whybipamenuitem")}
            </a>
          </Menu.Item>
        )}
        {this.state.current.includes("home") && (
          <Menu.Item key="abouthome">
            <a
              style={{
                color: menuItemColor,
              }}
              href="#about"
            >
              {t("aboutusmenuitem")}
            </a>
          </Menu.Item>
        )}
        {loggedIn === "false" && (
          <span>
            <Link to="client">
              <Button
                className={
                  this.state.current.includes("home") ||
                  this.props.clickOnLogo === "home"
                    ? "button-become-a-client-home"
                    : "button-become-a-client"
                }
                onClick={() => this.handleClickMenu({ key: "becomeaclient" })}
                shape="round"
                size="large"
              >
                {t("becomeaclientmenuitem")}
              </Button>
            </Link>
            <Link to="login">
              <Button
                className={
                  this.state.current.includes("home") ||
                  this.props.clickOnLogo === "home"
                    ? "button-login-home"
                    : "button-login"
                }
                onClick={() => this.handleClickMenu({ key: "login" })}
                type="ghost"
                shape="round"
                size="large"
              >
                {t("loginmenuitem")}
              </Button>
            </Link>
          </span>
        )}
        {loggedIn === "true" && (
          <span>
            {/* <Link to="client">
              <Button
                className={
                  this.state.current.includes("home") ||
                  this.props.clickOnLogo === "home"
                    ? "button-become-a-client-home"
                    : "button-become-a-client"
                }
                onClick={() => this.handleClickMenu({ key: "becomeaclient" })}
                shape="round"
                size="large"
              >
                {t("aquestion")}
              </Button>
            </Link> */}
            <Link to="yourBIPA">
              <Button
                className={
                  this.state.current.includes("home") ||
                  this.props.clickOnLogo === "home"
                    ? "button-become-a-client-home"
                    : "button-become-a-client"
                }
                onClick={() => this.handleClickMenu({ key: "yourBIPA" })}
                type="ghost"
                shape="round"
                size="large"
              >
                Dashboard
              </Button>
            </Link>
            <Button
              className={
                this.state.current.includes("home") ||
                this.props.clickOnLogo === "home"
                  ? "button-login-home"
                  : "button-login"
              }
              onClick={this.logout}
              icon={<LogoutOutlined />}
              shape="round"
              size="large"
              title={t("logout")}
            ></Button>
          </span>
        )}
      </Menu>
    );
  }
}

export default withTranslation()(TopMenu);
