import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Form, Input, Button, Row, Col, Typography, Card, message } from "antd";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "./becomeaclient.css";

const { Title } = Typography;
const { TextArea } = Input;

class Becomeaclient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      keyForm: Math.random(),
    };
  }

  handleName = (e) => {
    this.setState({ name: e.target.value });
  };

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleSubject = (e) => {
    this.setState({ subject: e.target.value });
  };

  handleMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  render() {
    const { t } = this.props;
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
    };

    const tailLayout = {
      wrapperCol: {
        xl: { offset: 4, span: 17 },
        md: { offset: 4, span: 17 },
        xs: { offset: 0, span: 14 },
      },
    };
    const resetFields = () => {
      this.setState({
        name: "",
        email: "",
        subject: "",
        message: "",
        keyForm: Math.random(),
      });
    };

    const checkSendMessage = () => {
      if (
        this.state.name !== "" &&
        this.state.email !== "" &&
        this.state.email.includes("@") &&
        this.state.email.includes(".") &&
        this.state.subject !== "" &&
        this.state.message !== ""
      ) {
        axios
          .post("http://127.0.0.1:5000/sendMail/", {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
            website: "BIPA"
          })
          .then(
            (response) => {
              resetFields();
              message.success(t("sucesssendemail"));
            },
            (error) => {
              console.log(error);
              message.error(t("errorsendemail"));
            }
          );
      }
    };

    return (
      <div>
        <Helmet>
          <title>Contact us to get more info | BIPA</title>
        </Helmet>
        <Row className="row-content">
          <Col className="col-contact" span={24}>
            <Card className="card-contact">
              <Row align="middle">
                <Col
                  xl={{ span: 10, offset: 0 }}
                  md={{ span: 10, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <div className="message-vector"></div>
                </Col>
                <Col
                  xl={{ span: 14, offset: 0 }}
                  md={{ span: 14, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Row>
                    <Col span={24}>
                      <Title>{t("contacttitle")}</Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form key={this.state.keyForm} {...layout}>
                        <Form.Item
                          label={t("contactform1")}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: t("requiredinput"),
                            },
                          ]}
                        >
                          <Input onChange={this.handleName} />
                        </Form.Item>
                        <Form.Item
                          label={t("contactform2")}
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: t("requiredemail"),
                            },
                            {
                              required: true,
                              message: t("requiredinput"),
                            },
                          ]}
                        >
                          <Input onChange={this.handleEmail} />
                        </Form.Item>
                        <Form.Item
                          label={t("contactform3")}
                          name="subject"
                          rules={[
                            {
                              required: true,
                              message: t("requiredinput"),
                            },
                          ]}
                        >
                          <Input onChange={this.handleSubject} />
                        </Form.Item>
                        <Form.Item
                          label={t("contactform4")}
                          name="message"
                          rules={[
                            {
                              required: true,
                              message: t("requiredinput"),
                            },
                          ]}
                        >
                          <TextArea onChange={this.handleMessage} rows={6} />
                        </Form.Item>
                        <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                          <Button
                            className="send-button"
                            type="primary"
                            htmlType="submit"
                            onClick={checkSendMessage}
                          >
                            {t("contactformbutton")}
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(Becomeaclient);
