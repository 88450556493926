import React, { Component } from "react";
import "./yourBIPA.css";
import { Helmet } from "react-helmet";
import { Row, Col, Tabs, Table, Button } from "antd";
import { withTranslation } from "react-i18next";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import ngena1 from "./../pdf/ngena/ngena - BIPA Report 2020-001.pdf";
import ngena2 from "./../pdf/ngena/ngena - BIPA Report 2020-002.pdf";
// import dummy from "./../pdf/ngena/dummy.pdf";
const { TabPane } = Tabs;
const ngena_info = [
  {
    type: "Corporate",
    values: "ngena, Accenture, América móvil, ngena@home, Viptela, Meraki",
  },
  {
    type: "Competitors",
    values:
      "Fortinet, Aryaka Networks, Velocloud by VMware, Silver Peak, Versa Networks",
  },
  {
    type: "Industry",
    values:
      "SD-WAN, SD-WAN as a service, SASE, Managed services, Business connectivity, Connectivity services, Connectivity platform, Long distance networks  ",
  },
  {
    type: "Trendsetters",
    values:
      "The Business Journals, NetworkWorld, Zdnet, SDxCentral, Forbes, Tech Target, 451 Research, Forrester Research, Gartner, IDC, TechRepublic, Silicon Angle, TMCnet, Channel Partners, Fierce Telecom, Evan Kirstel, Ronald Van Loon, Kirk Borne, John Shepler, Ray Mota, Rich Tehrani, R. Scott Raynovich, Zeus Kerravala, Keith Townsend, Diana Adams, Dean Bubley, TeleSintese, IT Forum 365, Época Negócios, Teletime, CIO Mexico, Mundo Contact, ComputerWorld, Portafolio, La República, Valora Analitik, Corporate IT, CIO, Channel News, Enfasys IT, Telesemana",
  },
];
const ngena = [
  {
    key: "1",
    name: "Performance Report #2020-001",
    date: "2020-07-03",
    options: ngena1,
  },
  {
    key: "2",
    name: "Performance Report #2020-002",
    date: "2020-08-07",
    options: ngena2,
  },
];
const citgo_info = [
  {
    type: "1",
    values: "citgo",
  },
  {
    type: "2",
    values: "petroleo",
  },
];
const citgo = [
  {
    key: "1",
    name: "citgo june 2020",
    date: "2020-06-25",
    options: ngena1,
  },
  {
    key: "2",
    name: "citgo july 2020",
    date: "2020-07-25",
    options: ngena2,
  },
];
class YourBIPA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      pdfToShow: "",
      client_documents: "",
      client_info: "",
    };
  }
  UNSAFE_componentWillMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    if (localStorage.getItem("BIPAWEBuserid") === "ngena") {
      this.setState({ client_documents: ngena, client_info: ngena_info });
    } else if (localStorage.getItem("BIPAWEBuserid") === "citgo") {
      this.setState({ client_documents: citgo, client_info: citgo_info });
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };
  callback = (key) => {
    // console.log(key);
  };

  render() {
    const { t } = this.props;
    const columns = [
      {
        title: t("documentstablecolname"),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: t("documentstablecoldate"),
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },
      {
        title: t("documentstablecoloptions"),
        dataIndex: "options",
        key: "options",
        width: 150,
        render: (pdfObject) => (
          <Row>
            <Col xl={{ span: 12 }} md={{ span: 0 }} xs={{ span: 0 }}>
              <a href={pdfObject} rel="noopener noreferrer" target="_blank">
                <Button
                  title={t("viewoptiontitle")}
                  className="options-documents-view"
                  icon={<EyeOutlined />}
                />
              </a>
            </Col>
            <Col xl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 12 }}>
              <a
                href={pdfObject}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <Button
                  title={t("downloadoptiontitle")}
                  className="options-documents-download"
                  icon={<DownloadOutlined />}
                />
              </a>
            </Col>
          </Row>
        ),
      },
    ];
    const columns_info = [
      {
        title: t("infotablecoltype"),
        dataIndex: "type",
        key: "type",
        width: 200,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: t("infotablecolvalues"),
        dataIndex: "values",
        key: "values",
      },
    ];
    return (
      <div>
        <Helmet>
          <title>
            Business Intelligence (BI) Software & Analytics Platform | BIPA
          </title>
        </Helmet>
        <Tabs
          style={{ margin: 20 }}
          defaultActiveKey="1"
          onChange={this.callback}
        >
          <TabPane tab={t("myinformationtab")} key="1">
            <Row>
              <Col
                style={{ minHeight: 600 }}
                xl={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
              >
                <Table
                  dataSource={this.state.client_info}
                  columns={columns_info}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t("mydocumentstab")} key="2">
            <Row>
              <Col
                style={{ minHeight: 600 }}
                xl={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
              >
                <Table
                  dataSource={this.state.client_documents}
                  columns={columns}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withTranslation()(YourBIPA);
