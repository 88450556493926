import React, { Component } from "react";
import { Router } from "@reach/router";
import "./template.css";
import { Layout } from "antd";
import HeaderTemplate from "../headerTemplate/headerTemplate";
import Home from "./../home/home";
import Login from "./../login/login";
import Becomeaclient from "./../becomeaclient/becomeaclient";
import YourBIPA from "./../yourBIPA/yourBIPA";
import FooterTemplate from "../footerTemplate/footerTemplate";

const { Header, Footer, Content } = Layout;

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItemSelected: "home",
    };
  }

  handleMenuSelected = (item) => {
    this.setState({ menuItemSelected: item });
  };

  render() {
    return (
      <Layout className="layout-bipa">
        <Header
          className={
            this.state.menuItemSelected.includes("home")
              ? "header-bipa-home"
              : "header-bipa"
          }
        >
          <HeaderTemplate menuSelectedTemplate={this.handleMenuSelected} />
        </Header>
        <Content>
          <Router>
            <Home path="/" />
            <Becomeaclient path="client" />
            <Login path="login" />
            <YourBIPA path="yourBIPA" />
          </Router>
        </Content>
        <Footer className="footer-bipa">
          <FooterTemplate></FooterTemplate>
        </Footer>
      </Layout>
    );
  }
}

export default Template;
