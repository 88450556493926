import React, { Suspense } from "react";
import Template from "./components/template/template";
import "./App.css";
import "antd/dist/antd.css";
import "./i18n";

function App() {
  return (
    <div className="App">
      <Suspense fallback={null}>
        <Template />
      </Suspense>
    </div>
  );
}

export default App;
