import React, { Component } from "react";
import { Row, Col, Typography } from "antd";
import "./headerTemplate.css";
import TopMenu from "./../topMenu/topMenu";
import { Link } from "@reach/router";
import { withTranslation } from "react-i18next";

const { Text } = Typography;

class HeaderTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItemSelected: "",
    };
  }

  UNSAFE_componentWillMount = () => {
    var item = "";
    if (window.location.pathname === "/") {
      item = "home";
      this.setState({ menuItemSelected: item });
    } else if (window.location.pathname === "/client") {
      item = "becomeaclient";
      this.setState({ menuItemSelected: item });
    } else if (window.location.pathname === "/login") {
      item = "login";
      this.setState({ menuItemSelected: item });
    }
    this.props.menuSelectedTemplate(item);
  };

  handleMenuSelected = (item) => {
    this.setState({ menuItemSelected: item });
    this.props.menuSelectedTemplate(item);
  };

  render() {
    const { i18n } = this.props;
    const handleLanguage = (languageBIPAWEB) => {
      localStorage.setItem("languageBIPAWEB", languageBIPAWEB);
      i18n.changeLanguage(languageBIPAWEB);
    };
    return (
      <Row>
        <Col xl={9} md={9} xs={15}>
          <Row>
            <Col xl={6} md={12} xs={18}>
              <Link onClick={() => this.handleMenuSelected("home")} to="/">
                <div
                  className={
                    this.state.menuItemSelected.includes("home")
                      ? "logoWhite"
                      : "logoBlack"
                  }
                />
              </Link>
            </Col>
            <Col xl={{ span: 6, offset: 0 }} md={8} xs={{ span: 6, offset: 0 }}>
              <Text
                className={
                  this.state.menuItemSelected.includes("home")
                    ? "languages-white"
                    : "languages-black"
                }
              >
                <span
                  role="img"
                  aria-label="en"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLanguage("en")}
                >
                  🇺🇸
                </span>{" "}
                |{" "}
                <span
                  role="img"
                  aria-label="es"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLanguage("es")}
                >
                  🇪🇸
                </span>
              </Text>
            </Col>
          </Row>
        </Col>
        <Col xl={15} md={15} xs={9}>
          <TopMenu
            clickOnLogo={this.state.menuItemSelected}
            menuSelectedHeaderTemplate={this.handleMenuSelected}
          />
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(HeaderTemplate);
