import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Form, Input, Button, Row, Col, Card, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loadingLogin: false,
    };
  }

  handleUsername = (e) => {
    this.setState({ username: e.target.value });
  };

  handlePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  render() {
    const { t } = this.props;
    const loginCredentials = { ngena: "ngenabipa", citgo: "citgo" };
    const checkLogin = () => {
      if (this.state.username !== "" && this.state.password !== "") {
        if ("undefined" !== typeof loginCredentials[this.state.username.toLowerCase()]) {
          if (this.state.password === loginCredentials[this.state.username.toLowerCase()]) {
            localStorage.setItem("loggedIn", "true");
            localStorage.setItem("BIPAWEBuserid", this.state.username.toLowerCase());
            window.location.href = "./yourBIPA";
          } else {
            message.error(t("errorlogin"));
          }
        } else {
          message.error(t("errorlogin"));
        }
      }
    };
    const layout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
    };

    const tailLayout = {
      wrapperCol: {
        xl: { span: 24 },
        md: { offset: 4, span: 17 },
        xs: { offset: 0, span: 14 },
      },
    };
    return (
      <div>
        <Helmet>
          <title>Welcome to BIPA client system</title>
        </Helmet>
        <Row className="row-login" align="middle">
          <Col xl={{ span: 12 }} xs={{ span: 24 }} md={{ span: 12 }}>
            <div onLoad={null} className="login-image"></div>
          </Col>
          <Col xl={{ span: 12 }} xs={{ span: 24 }} md={{ span: 12 }}>
            <Card className="card-login">
              <Form {...layout}>
                <Form.Item
                  label=""
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t("requiredinput"),
                    },
                  ]}
                >
                  <Input
                    onChange={this.handleUsername}
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("requiredinput"),
                    },
                  ]}
                >
                  <Input.Password
                    onChange={this.handlePassword}
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    onClick={checkLogin}
                    className="send-button"
                    type="primary"
                    htmlType="submit"
                  >
                    {t("loginbutton")}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(Login);
